






































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Comments from '@/components/Comments.vue'

@Component({ components: { Comments } })
export default class Main extends Vue {}
